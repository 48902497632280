import {
  CardNumberElement,
  Elements,
  FpxBankElement,
} from "@stripe/react-stripe-js";
import {
  loadStripe,
  PaymentMethod,
  Stripe,
  StripeElements,
} from "@stripe/stripe-js";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ReactSVG from "react-svg";

import { useLocalStorage } from "@hooks";
import { paymentErrorMessages } from "@temp/intl";
import { IFormError } from "@types";

import { StripeCreditCardForm } from "../StripeCreditCardForm";
import { StripeFpxForm } from "../StripeFpxForm";
import { StripeGrabForm } from "../StripeGrabForm";
import { stripeErrorMessages } from "./intl";
import * as S from "./styles";
import { IProps } from "./types";
import {
  handleConfirmCardPayment,
  parsePaymentConfirmationData,
} from "./utils";

import cardIcon from "images/card.svg";
import fpxIcon from "images/fpx.svg";
import grabpayIcon from "images/grabpay.jpg";

/**
 * Stripe payment gateway.
 */
const StripePaymentGateway: React.FC<IProps> = ({
  config,
  processPayment,
  submitPayment,
  submitPaymentSuccess,
  formRef,
  formId,
  errors = [],
  onError,
}: IProps) => {
  const intl = useIntl();

  const dataCheckout = useLocalStorage("data_checkout", "");

  const [submitErrors, setSubmitErrors] = useState<IFormError[]>([]);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
  const [paymentType, setPaymentType] = useState<string | string[]>("card");

  const apiKey = config.find(({ field }) => field === "api_key")?.value;

  const stripePromise = useMemo(() => {
    if (apiKey) {
      return loadStripe(apiKey);
    }
    const stripeApiKeyErrors = [
      new Error(intl.formatMessage(stripeErrorMessages.gatewayMisconfigured)),
    ];
    setSubmitErrors(stripeApiKeyErrors);
    onError(stripeApiKeyErrors);
    return null;
  }, [apiKey]);

  const handleFormSubmit = async (
    stripe: Stripe | null,
    elements: StripeElements | null
  ) => {
    if (paymentType === "card") {
      const cartNumberElement = elements?.getElement(CardNumberElement);

      if (!cartNumberElement) {
        const stripeElementsErrors = [
          new Error(
            intl.formatMessage(stripeErrorMessages.geytwayDisplayError)
          ),
        ];
        setSubmitErrors(stripeElementsErrors);
        onError(stripeElementsErrors);
        return;
      }

      const payload = await stripe?.createPaymentMethod({
        type: "card",
        card: cartNumberElement,
      });

      if (payload?.error) {
        const errors = [
          {
            ...payload.error,
            message: payload.error.message || "",
          },
        ];
        setSubmitErrors(errors);
        onError(errors);
        return;
      }

      if (!payload?.paymentMethod) {
        const stripePayloadErrors = [
          new Error(
            intl.formatMessage(stripeErrorMessages.paymentSubmissionError)
          ),
        ];
        setSubmitErrors(stripePayloadErrors);
        onError(stripePayloadErrors);
        return;
      }

      const { card } = payload.paymentMethod;
      if (card?.brand && card?.last4) {
        // processPayment(id, {
        //   brand: card?.brand,
        //   expMonth: card?.exp_month || null,
        //   expYear: card?.exp_year || null,
        //   firstDigits: null,
        //   lastDigits: card?.last4,
        // });
        processPayment("creditcard");
        setPaymentMethod(payload.paymentMethod);
      }
    } else if (paymentType === "fpx") {
      const fpxBankElement = elements?.getElement(FpxBankElement);

      const payload = await stripe?.createPaymentMethod({
        type: "fpx",
        fpx: fpxBankElement,
      });

      if (payload?.error) {
        const errors = [
          {
            ...payload.error,
            message: payload.error.message || "",
          },
        ];
        setSubmitErrors(errors);
        onError(errors);
        return;
      }

      processPayment("ebanking");
      setPaymentMethod(payload?.paymentMethod);
    } else if (paymentType === "grabpay") {
      const payload = await stripe?.createPaymentMethod({
        type: "grabpay",
      });

      if (payload?.error) {
        const errors = [
          {
            ...payload.error,
            message: payload.error.message || "",
          },
        ];
        setSubmitErrors(errors);
        onError(errors);
        return;
      }

      processPayment("grabpay");
      setPaymentMethod(payload?.paymentMethod);
    }
  };

  const handleFormCompleteSubmit = async () => {
    const stripe = await stripePromise;
    const payment = await submitPayment();

    if (payment.errors?.length) {
      onError(payment.errors);
      return;
    }

    if (!payment?.confirmationNeeded) {
      submitPaymentSuccess(payment?.order);
      return;
    }

    if (!stripe?.confirmCardPayment) {
      onError([
        new Error(
          intl.formatMessage(
            paymentErrorMessages.cannotHandlePaymentConfirmation
          )
        ),
      ]);
      return;
    }

    if (!payment?.confirmationData) {
      onError([
        new Error(
          intl.formatMessage(paymentErrorMessages.paymentNoConfirmationData)
        ),
      ]);
      return;
    }

    const { parseError, paymentAction } = parsePaymentConfirmationData(
      payment.confirmationData
    );

    if (parseError || !paymentAction) {
      onError([
        new Error(
          intl.formatMessage(
            paymentErrorMessages.paymentMalformedConfirmationData
          )
        ),
      ]);
      return;
    }

    if (!paymentMethod?.id) {
      onError([
        new Error(
          intl.formatMessage(stripeErrorMessages.paymentMethodNotCreated)
        ),
      ]);
      return;
    }

    const { confirmation, confirmationError } = await handleConfirmCardPayment(
      stripe,
      paymentAction,
      paymentMethod,
      dataCheckout?.storedValue?.id
    );

    if (confirmationError) {
      onError([new Error(confirmationError)]);
      return;
    }

    if (confirmation?.error) {
      onError([new Error(confirmation.error.message)]);
      return;
    }

    handleFormCompleteSubmit();
  };

  useEffect(() => {
    if (stripePromise) {
      (formRef?.current as any)?.addEventListener(
        "submitComplete",
        handleFormCompleteSubmit
      );
    }
    return () => {
      (formRef?.current as any)?.removeEventListener(
        "submitComplete",
        handleFormCompleteSubmit
      );
    };
  }, [formRef, stripePromise, paymentMethod]);

  const allErrors = [...errors, ...submitErrors];

  return (
    <div data-test="stripeGateway">
      <S.Wrapper>
        <S.Tile checked={paymentType === "card"}>
          <S.Radio checked={paymentType === "card"}>
            <input
              type="radio"
              data-test="checkoutPaymentGatewayStripeInput"
              name="payment-method-card"
              value="stripe"
              checked={paymentType === "card"}
              onChange={() => setPaymentType("card")}
            />
            <S.PaymentIcon>
              <ReactSVG path={cardIcon} />
            </S.PaymentIcon>
            <span>
              <FormattedMessage id="Credit Card" />
            </span>
          </S.Radio>
        </S.Tile>
        <S.Tile checked={paymentType === "fpx"}>
          <S.Radio checked={paymentType === "fpx"}>
            <input
              type="radio"
              data-test="checkoutPaymentGatewayStripeInput"
              name="payment-method-fpx"
              value="stripe"
              checked={paymentType === "fpx"}
              onChange={() => setPaymentType("fpx")}
            />
            <S.PaymentIcon>
              <ReactSVG path={fpxIcon} />
            </S.PaymentIcon>
            <span>
              <FormattedMessage id="Online Banking" />
            </span>
          </S.Radio>
        </S.Tile>
        <S.Tile checked={paymentType === "grabpay"}>
          <S.Radio checked={paymentType === "grabpay"}>
            <input
              type="radio"
              data-test="checkoutPaymentGatewayStripeInput"
              name="payment-method-grab"
              value="stripe"
              checked={paymentType === "grabpay"}
              onChange={() => setPaymentType("grabpay")}
            />
            <S.PaymentIcon>
              <img alt="grabpay" src={grabpayIcon} />
            </S.PaymentIcon>
            <span>
              <FormattedMessage id="Grab Pay" />
            </span>
          </S.Radio>
        </S.Tile>
      </S.Wrapper>
      <Elements stripe={stripePromise}>
        {paymentType === "card" && (
          <StripeCreditCardForm
            formId={formId}
            formRef={formRef}
            errors={allErrors}
            onSubmit={handleFormSubmit}
          />
        )}
        {paymentType === "fpx" && (
          <StripeFpxForm
            formId={formId}
            formRef={formRef}
            errors={allErrors}
            onSubmit={handleFormSubmit}
          />
        )}
        {paymentType === "grabpay" && (
          <StripeGrabForm
            formId={formId}
            formRef={formRef}
            errors={allErrors}
            onSubmit={handleFormSubmit}
          />
        )}
      </Elements>
    </div>
  );
};

export { StripePaymentGateway };
