import React from "react";

import { ErrorMessage } from "@components/atoms";

import { StripePaymentGateway } from "..";
import * as S from "./styles";
import { IProps } from "./types";

/**
 * Payment Gateways list
 */
const PaymentGatewaysList: React.FC<IProps> = ({
  paymentGateways,
  selectedPaymentGateway,
  selectedPaymentGatewayToken,
  selectPaymentGateway,
  formRef,
  formId,
  processPayment,
  submitPayment,
  submitPaymentSuccess,
  errors,
  onError,
}: IProps) => {
  return (
    <S.Wrapper>
      {paymentGateways.map(({ id, name, config }, index) => {
        // const checked = selectedPaymentGateway === id;
        return (
          <div key={index}>
            {/* <S.Tile checked={checked}>
              <Radio
                data-test="checkoutPaymentGatewayStripeInput"
                name="payment-method"
                value="stripe"
                checked={checked}
                onChange={() => 
                  selectPaymentGateway && selectPaymentGateway(id)
                }
                customLabel
              >
                <span data-test="checkoutPaymentGatewayStripeName">
                  {name}
                </span>
              </Radio>
            </S.Tile> */}

            <StripePaymentGateway
              config={config}
              formRef={formRef}
              formId={formId}
              processPayment={event => processPayment(id, "", event)}
              submitPayment={submitPayment}
              submitPaymentSuccess={submitPaymentSuccess}
              errors={errors}
              onError={onError}
            />
          </div>
        );
      })}
      {!selectedPaymentGateway && errors && <ErrorMessage errors={errors} />}
    </S.Wrapper>
  );
};

export { PaymentGatewaysList };
