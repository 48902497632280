import { PaymentMethod, Stripe } from "@stripe/stripe-js";

interface StripeConfirmationData {
  client_secret: string;
  id: string;
}

export const parsePaymentConfirmationData = (confirmationData: string) => {
  try {
    const paymentAction = JSON.parse(
      confirmationData
    ) as StripeConfirmationData;
    return {
      paymentAction,
    };
  } catch (parseError) {
    return {
      parseError,
    };
  }
};

export const handleConfirmCardPayment = async (
  stripe: Stripe,
  paymentAction: StripeConfirmationData,
  paymentMethod: PaymentMethod,
  checkoutId?: string
) => {
  try {
    // @ts-ignore
    if (paymentMethod?.grabpay) {
      // @ts-ignore
      const confirmation = await stripe.confirmGrabPayPayment(
        paymentAction.client_secret,
        {
          return_url: `${window.location.origin}/order-finalized?checkoutId=${checkoutId}&type=grabpay`,
        }
      );
      return {
        confirmation,
      };
    }

    if (paymentMethod?.fpx) {
      const confirmation = await stripe.confirmFpxPayment(
        paymentAction.client_secret,
        {
          payment_method: paymentMethod.id,
          return_url: `${window.location.origin}/order-finalized?checkoutId=${checkoutId}&type=fpx`,
        }
      );
      return {
        confirmation,
      };
    }

    if (paymentMethod?.card) {
      const confirmation = await stripe.confirmCardPayment(
        paymentAction.client_secret,
        {
          payment_method: paymentMethod.id,
          return_url: `${window.location.origin}/order-finalized?checkoutId=${checkoutId}&type=card`,
        }
      );
      return {
        confirmation,
      };
    }
  } catch (confirmationError) {
    return {
      confirmationError,
    };
  }
};
