import { DefaultTheme, styled } from "@styles";

type WrapperProps = {
  active?: boolean;
  error?: boolean;
  disabled?: boolean;
  theme: DefaultTheme;
};

export const Form = styled.form`
  padding: 2rem 0;
`;

export const Card = styled.div<WrapperProps>`
  display: grid;
  grid-template-areas:
    "cardNumber cardNumber cardNumber cardNumber cardNumber "
    "cardExpiry cardExpiry cardExpiry cardCvc cardCvc";
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: ${props => props.theme.spacing.fieldSpacer};

  .StripeElement {
    padding: 0.8rem 1rem;
    margin: 0;
    width: 100%;
    font-size: ${props => props.theme.typography.baseFontSize};
    outline: none;
    background-color: transparent;
    ::placeholder {
      font-size: 0;
      visibility: hidden;
    }
    &--empty {
      font-size: 0;
    }
  }
`;

export const CardNumberField = styled.div`
  grid-area: cardNumber;
`;

export const CardExpiryField = styled.div`
  grid-area: cardExpiry;
`;

export const CardCvcField = styled.div`
  grid-area: cardCvc;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 20px;
`;

export const Tile = styled.label<{ checked: boolean }>`
  display: block;
  background-color: ${props => props.theme.colors.primaryTransparent};
  padding: 20px;
  ${props =>
    props.checked &&
    `border: 2px solid #FFF; background-color: ${props.theme.colors.primary}`}
  cursor: pointer;
  border-radius: 1rem;
`;

export const Radio = styled.div<{ checked: boolean }>`
  ${props => props.checked && `color: #21125E;`}

  display: flex;
  align-items: center;
  cursor: pointer;

  input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
  }

  ${props => props.checked && `color: white`};
`;

export const PaymentIcon = styled.div`
  display: flex;
  width: 100px;
  height: 100px;
  margin-right: 1rem;
  align-items: center;
  svg {
    width: 100%;
    height: 100%;
  }
  img {
    width: auto;
    height: auto;
    object-fit: contain;
    border-radius: 5px;
  }
`;
